import WebApp from 'tma-dev-sdk';
import { model, useDirectives } from 'solid-utils/model';
import { children, createRenderEffect, createSignal, Show } from 'solid-js';
import type { JSX } from 'solid-js';

export default function Demo() {
  return <main class="= p-4">
    <ButtonDemo object={WebApp.MainButton} />
    <ButtonDemo object={WebApp.SecondaryButton} />
    <br />
    <section id="top_sect" class="= second">
      <button onClick={() => WebApp.expand()}>Expand Webview</button>
    </section>
    <br/>
    <hr/>
    <section>
      <h3>Test alerts:</h3>
      <div class="= columns flex flex-col gap-4">
        <div class="= reset-list flex flex-col gap-2">
          <div><button onClick={(e) => (e.preventDefault(), alert('Hello!'))}>alert</button></div>
          <div><button onClick={(e) => (e.preventDefault(), confirm('Are you sure?'))}>confirm</button></div>
          <div><button onClick={(e) => (e.preventDefault(), prompt('How old are you?'))}>prompt</button></div>
        </div>
        <div class="= reset-list flex flex-col gap-2">
          <div><button onClick={(e) => (e.preventDefault(), WebApp.showAlert('Hello!'))}>showAlert</button></div>
          <div><button onClick={(e) => (e.preventDefault(), WebApp.showConfirm('Are you sure?'))}>showConfirm</button></div>
          <div><button onClick={(e) => (e.preventDefault(), WebApp.showPopup({ message: 'Popup!' }))}>showPopup</button></div>
          <div><button onClick={(e) => (e.preventDefault(), WebApp.showScanQrPopup({ }))}>showScanQrPopup</button></div>
          <div><button onClick={(e) => (e.preventDefault(), WebApp.openInvoice('https://t.me/$Oos-L764eUosDAAAv3wDhKBEC-o'))}>show invoice</button></div>
        </div>
      </div>
    </section>
    <br/>
    <hr/>
    <section>
      <h3>Haptics:</h3>
      <div class="= reset-list flex flex-col gap-2">
        <div>
          Impact: <button onClick={(e) => (e.preventDefault(), WebApp.HapticFeedback.impactOccurred('heavy'))}>heavy</button>,
          &nbsp; <button onClick={(e) => (e.preventDefault(), WebApp.HapticFeedback.impactOccurred('light'))}>light</button>,
          &nbsp; <button onClick={(e) => (e.preventDefault(), WebApp.HapticFeedback.impactOccurred('medium'))}>medium</button>,
          &nbsp; <button onClick={(e) => (e.preventDefault(), WebApp.HapticFeedback.impactOccurred('rigid'))}>rigid</button>,
          &nbsp; <button onClick={(e) => (e.preventDefault(), WebApp.HapticFeedback.impactOccurred('soft'))}>soft</button>
        </div>
        <div>
          Notification: <button onClick={(e) => (e.preventDefault(), WebApp.HapticFeedback.notificationOccurred('error'))}>error</button>,
          &nbsp; <button onClick={(e) => (e.preventDefault(), WebApp.HapticFeedback.notificationOccurred('success'))}>success</button>,
          &nbsp; <button onClick={(e) => (e.preventDefault(), WebApp.HapticFeedback.notificationOccurred('warning'))}>warning</button>
        </div>
        <div>
          Selection: <button onClick={(e) => (e.preventDefault(), WebApp.HapticFeedback.selectionChanged())}>changed</button>
        </div>
      </div>
    </section>
    <br/>
    <hr/>
    <section>
      <h3>Test links:</h3>
      <div class="= reset-list flex flex-col gap-2">
        <div><a href="/">Regular link #2</a> (opens inside webview)</div>
        <div><a href="https://telegram.org/" target="_blank">target="_blank" link</a> (opens outside webview)</div>
        <div><a href="javascript:window.open('https://telegram.org/');">window.open() link</a> (opens outside webview)</div>
        <div><a href="https://t.me/like">LikeBot t.me link</a> (opens inside Telegram app)</div>
        <div><button onClick={(e) => (e?.preventDefault(), WebApp.openTelegramLink('https://t.me/vote'))}>web_app_open_tg_link()</button> (opens inside Telegram app)</div>
        <div><button onClick={(e) => (e?.preventDefault(), WebApp.openLink('https://google.com/'))}>web_app_open_link()</button> (opens outside webview)</div>
        <div><a href="tg://resolve?domain=vote">VoteBot tg:// link</a> (does not open)</div>
        <div><button onClick={(e) => (e?.preventDefault(), WebApp.openLink('https://telegra.ph/api', { try_instant_view:true }))}>web_app_open_link (try_instant_view:true)</button> (opens IV inside Telegram app)</div>
      </div>
    </section>
    <br/>
    <hr/>
    <section>
      <h3>Data passed to webview:</h3>
      <div>
        <Show
          when={WebApp.initDataUnsafe.query_id}
          fallback={<span>No Data...</span>}
        >
          <pre class="text-start"><code>{ JSON.stringify(WebApp.initDataUnsafe, null, 2) }</code></pre>
        </Show>
      </div>

      <h3>Theme params</h3>
      <pre class="text-start"><code>{ JSON.stringify(WebApp.themeParams, null, 2) }</code></pre>
      <div>
        Version: <span>{ WebApp.version }</span>,
        platform: <span>{ WebApp.platform }</span>
      </div>
    </section>
  </main>;
}

export function Section<T>(props: {
  title: string;
} & ({
  init: () => T;
  children: (context: T) => JSX.Element;
} | {
  init?: VoidFunction;
  children: JSX.Element;
})) {
  const resolved = children(() => {
    if (typeof props.init !== 'function') {
      return props.children as JSX.Element;
    }

    const context = props.init();

    if (typeof props.children === 'function') {
      return props.children(context as T);
    }

    return props.children;
  });

  return <section class="= flex flex-col gap-4 py-4 b-b-1 b-b-solid b-b-tg_text last:b-b-transparent">
    <h2 class="= mb-0 capitalize">{props.title}</h2>
    {resolved()}
  </section>;
}

function ButtonDemo(props: {
  object: typeof WebApp.MainButton,
}) {
  const type = () => {
    try {
      // @ts-expect-error library typings need to be updated
      return props.object.type + ' button';
    } catch {
      return Object.keys(WebApp).find(k => WebApp[k as keyof typeof WebApp] === props.object) ?? 'Null';
    }
  };

  return <Section title={type()}
    init={() => {
      const text = createSignal(type());

      createRenderEffect(() => {
        props.object.setText(text[0]());
      });

      useDirectives(model);

      return {
        text,
        show: () => props.object.show(),
        hide: () => props.object.hide(),
        showProgress: () => props.object.showProgress(),
        hideProgress: () => props.object.hideProgress(),
      };
    }}
  >{(ctx) => <>
    <div class="= flex gap-2 items-center">
      <p class="= m-0">Text:</p>
      <input type="text" class="= b-solid b-border b-1 rounded-2 flex-grow"
        use:model={ctx.text}
      />
    </div>
    <div class="= flex flex-wrap gap-2">
      <button class="= rounded-2" onClick={ctx.show}>
        Show {type()}
      </button>
      <button class="= rounded-2" onClick={ctx.hide}>
        Hide {type()}
      </button>
      <button class="= rounded-2" onClick={ctx.showProgress}>
        Show Loader
      </button>
      <button class="= rounded-2" onClick={ctx.hideProgress}>
        Hide Loader
      </button>
    </div>
  </>}</Section>;
}
